@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null) {
    @font-face {
      font-display: swap;
      font-family: quote($name);
      font-style: $style;
      font-weight: $weight;
      src: url(quote($path));
    }
  }
