@import '../../styles/variables.scss';

.form-field {
  margin-bottom: 1rem;

  &__label {
    font-size: map-get($font-sizes, smaller);
    color: map-get($colors, clear-black)
  }

  &__error {
    &.invalid-feedback {
      display: block;
      margin-top: 0;
      font-size: map-get($font-sizes, smallest);
      line-height: 17px;
    }
  }
}