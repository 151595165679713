@import './variables.scss';
@import './mixins.scss';

@include font-face(
  $name: 'SF-Pro-Display',
  $path: $font-path + 'SF-Pro-Display-Bold.otf',
  $weight: map-get($font-weights, bold), $style: normal
);

@include font-face(
  $name: 'SF-Pro-Display',
  $path: $font-path + 'SF-Pro-Display-Semibold.otf',
  $weight: map-get($font-weights, semibold), $style: normal
);

@include font-face(
  $name: 'SF-Pro-Display',
  $path: $font-path + 'SF-Pro-Display-Medium.otf',
  $weight: map-get($font-weights, medium), $style: normal
);

@include font-face(
  $name: 'SF-Pro-Display',
  $path: $font-path + 'SF-Pro-Display-Regular.otf',
  $weight: map-get($font-weights, regular), $style: normal
);

@include font-face(
  $name: 'SF-Pro-Display',
  $path: $font-path + 'SF-Pro-Display-RegularItalic.otf',
  $weight: map-get($font-weights, regular), $style: italic
);