@import '../../styles/variables.scss';

.plazus-button {
  padding: 6px 12px;
  background: map-get($colors, clear-white);
  font-weight: map-get($font-weights, medium);
  border: 1px solid map-get($colors, common-orange);

  &.login-button {
    &:focus {
      box-shadow: 0px 0px 0px 3px #f7926380;
    }
  }

  span {
    line-height: 2;
    margin: 0 5px;
  }

  &.outlined-button {
    transition: all .3s;

    &:hover {
      background: map-get($colors, common-orange);
    }
    &:focus {
      background: map-get($colors, common-orange);
    }
  }

  &.filled-button {
    background: map-get($colors, common-orange);
    transition: all .3s;

    &:hover {
      background: map-get($colors, filled-orange-hover);
      border-color: map-get($colors, filled-orange-hover);
    }
  }
}