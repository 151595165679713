@import '../../styles/variables.scss';

.dropdown {

  &-toggle {
    &::after {
      display: none !important;
    }
  }

  &-menu {
    position: absolute;
    padding-top: 0.25rem;
    border: none !important;

    .dropdown-item {
      padding: 0.25rem 1.5rem;
      border-bottom: 2px solid map-get($colors, common-orange);
      border-left: 2px solid map-get($colors, common-orange);
      border-right: 2px solid map-get($colors, common-orange);
      cursor: pointer;
  
      &:nth-child(1) {
        border-top: 2px solid map-get($colors, common-orange);
      }
  
      &:hover {
        background: map-get($colors, grey-hover);
      }
      &:active {
        background: map-get($colors, blue-active);
      }
    }
  }
}
