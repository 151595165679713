@import '../../styles/variables.scss';

.card-box {
  min-width: 170px;
  max-width: 180px;
  font-weight: map-get($font-weights, bold);
  color: map-get($colors, black);

  &__title {
    font-size: map-get($font-sizes, smaller);
    font-weight: inherit;
  }

  &__content {
    width: 100%;
    height: 125px;
    border: 1px solid map-get($colors, light-grey2);

    &--item {
      span {
        font-size: map-get($font-sizes, biggest);
      }
    }
  }
}