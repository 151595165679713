@import '../../styles/variables.scss';

.app-navbar {
  &__item {
    margin: 0 10px;
  }
}

.buttons-wrapper {
  .export-button,
  .cancel-button {
    border-radius: $border-radius;
    margin-left: 8px;
    border: none;
  
    span {
      font-weight: map-get($font-weights, regular);
    }
  }
  
  .export-button {
    background-color: map-get($colors, blue);
    transition: all .3s ease;
  
    span {
      color: map-get($colors, clear-white);
    }
  
    &:hover {
      background-color: map-get($colors, blue-hover);
    }
  }
  
  .cancel-button {
    span {
      color: map-get($colors, clear-black);
    }
  }
}