.login-page {
  height: 100vh;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 300px;
    transform: translate(-50%, -50%);
    padding: 30px;
    background-color: white;
    border-radius: 8px;
  }

  &__icon {
    text-align: center;
    margin-bottom: 30px;
  }
}