$font-path: '../assets/fonts/';

$base-font-size-count: 16;
$base-font-size: $base-font-size-count * 1px;
$relative-pixels: (1 / $base-font-size-count);
$rem-pixels: $relative-pixels * 1rem;
$border-radius: 5px;

$font-weights: (
  bold: 700,
  semibold: 600,
  medium: 500,
  regular: 400,
);

$font-sizes: (
  smallest: 12 * $rem-pixels,
  smaller: 14 * $rem-pixels,
  base: 16 * $rem-pixels,
  biggest: 24 * $rem-pixels
);

$colors: (
  black: #000000,
  common-orange: #f79263,
  clear-white: #ffffff,
  clear-black: #000000,
  text-color: #343a40,
  filled-orange-hover: #f5793f,
  danger: #721c24,
  pink: #f6ced3,
  light-pink: #fcf0f1,
  grey: #495057,
  grey-hover: #49505720,
  light-grey: #d8d8d8,
  light-grey2: #979797,
  blue: #0088cc,
  blue-active: #007bff,
  blue-hover: #0069d9,
  light-blue: #ebf4f8,
  salad: #28a745,
  salad-hover: #218838,
)