@import '../../styles/variables.scss';

.section-header {
  margin-bottom: 30px;

  &__title {
    font-size: map-get($font-sizes, smaller);
    font-weight: map-get($font-weights, bold);
    margin-bottom: 15px;
  }

  .total-item {
    padding: 0 20px;
    &:not(:last-child) {
      border-right: 1px solid map-get($colors, light-grey);
    }
  }

  .total-label {
    font-size: map-get($font-sizes, biggest);
    font-weight: map-get($font-weights, regular);
  }

  .total-value {
    font-size: map-get($font-sizes, biggest);
    font-weight: map-get($font-weights, bold);
  }
}