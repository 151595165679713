@import './fonts.scss';
@import './variables.scss';

* {
  font-family: 'SF-Pro-Display';
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: map-get($colors, text-color);
  line-height: 24px;
}

a {
  text-decoration: none
}

.page {
  min-height: 100vh;
}

ul, ol {
  list-style: none;
}

p, ul, li {
  padding: 0;
  margin: 0;
}

.graph-container {
  padding: 40px;
  border-top: 1px solid map-get($colors, light-grey2);
}

.funnel-separation-line {
  max-width: 480px;
  font-size: map-get($font-sizes, smaller);
  font-weight: map-get($font-weights, regular);
  border-top: 2px solid map-get($colors, black);
  color: map-get($colors, black)
}