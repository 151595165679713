@import '../../styles/variables.scss';

.toast {
  border: 1px solid map-get($colors, pink) !important;

  &.bg-danger {
    .toast-header {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom: 1px solid map-get($colors, pink);
      strong {
        color: map-get($colors, danger) !important
      }
    }
    .toast-body {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      background: map-get($colors, light-pink);
      color: map-get($colors, danger) !important;
      font-weight: map-get($font-weights, regular);
    }
  }
}