@import '../../styles/variables.scss';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

.calendar {
  &__input {
    .form-field {

      &__label {
        font-size: map-get($font-sizes, base);
        font-weight: map-get($font-weights, regular);
        color: map-get($colors, clear-black);
      }

      input {
        color: map-get($colors, grey);
        font-weight: map-get($font-weights, regular);
        text-align: center;
      }
    }
  }
}

.date-range-wrapper {
  position: relative;
  
  .date-range-footer {
    position: absolute;
    width: 680px;
    top: 320px;
    left: -107px;
    background-color: map-get($colors, clear-white);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    &-value {
      font-size: map-get($font-sizes, smallest);
      padding-right: 8px;
    }

    &-buttons {
      .date-range-button {
        margin-left: 8px;
        border-radius: $border-radius;
        border: none;

        span {
          font-weight: map-get($font-weights, regular);
          font-size: map-get($font-sizes, smallest);
        }
      }

      .date-range-apply {
        background-color: map-get($colors, salad);
        transition: all .3s ease;

        span {
          color: map-get($colors, clear-white);
          transition: all .3s ease;
        }

        &:hover {
          background-color: map-get($colors, salad-hover);
        }
      }

      .date-range-cancel {
        background-color: #6c757d;
        transition: all .3s ease;

        span {
          color: map-get($colors, clear-white);
          transition: all .3s ease;
        }

        &:hover {
          background-color: map-get($colors, common-orange);

          span {
            color: map-get($colors, clear-white);
          }
        }
      }
    }
  }
}

.rdrDateRangePickerWrapper {
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
  width: 680px;
  height: 320px;
  border: 1px solid map-get($colors, light-grey);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(50%, -55%) rotate(45deg);
    background-color: map-get($colors, clear-white);
    border-top: 1px solid map-get($colors, light-grey);
    border-left: 1px solid map-get($colors, light-grey);
  }

  .rdrDefinedRangesWrapper {
    width: 140px;

    .rdrStaticRange {
      .rdrStaticRangeLabel {
        padding: 8px 12px;
        font-size: map-get($font-sizes, smallest);
        font-weight: map-get($font-weights, regular);
      }
    }
  }

  .rdrDateRangeWrapper {
    width: 540px;
  }

  button {
    border: none
  }
}

.rdrStaticRange {
  &.rdrStaticRangeSelected {
    background-color: map-get($colors, blue);
    span {
      color: map-get($colors, clear-white);
      font-weight: map-get($font-weights, regular);
    }


    &:hover {
      background: map-get($colors, blue) !important;
      color: map-get($colors, clear-white);

      span {
        color: map-get($colors, clear-white);
        font-weight: map-get($font-weights, regular);
      }
    }
  }
}

.rdrDayNumber {
  span {
    font-size: map-get($font-sizes, smallest);
  }
}

.rdrWeekDay {
  color: map-get($colors, black);
  font-weight: map-get($font-weights, medium);
  font-size: map-get($font-sizes, smallest);
  font-weight: map-get($font-weights, regular);
}

.rdrMonthName {
  padding: 5px 0;
  text-align: center;
  color: map-get($colors, black);
  font-size: map-get($font-sizes, smaller);
}

.rdrDay {
  height: 35px;
}

.rdrDay:not(.rdrDayPassive) {
  .rdrStartEdge,
  .rdrEndEdge {
    color: map-get($colors, blue) !important;
  
    & ~ .rdrDayNumber {
      span {
        color: map-get($colors, clear-white) !important;
      }
    }
  }
  .rdrInRange {
    color: map-get($colors, light-blue) !important;
  
    & ~ .rdrDayNumber {
      span {
        color: map-get($colors, black) !important;
      }
    }
  }
}

.rdrDayDisabled {
  .rdrDayNumber {
    span {
      text-decoration: line-through;
    }
  }
}
