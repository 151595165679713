@import '../../styles/variables.scss';

.bold {
  font-family: 'SF-Pro-Display';
  font-weight: map-get($font-weights, bold);
}
.semibold {
  font-family: 'SF-Pro-Display';
  font-weight: map-get($font-weights, semibold);
}
.regular {
  font-family: 'SF-Pro-Display';
  font-weight: map-get($font-weights, regular);
}
.normal {
  font-family: 'SF-Pro-Display';
  font-weight: map-get($font-weights, medium);
}
.regular-italic {
  font-family: 'SF-Pro-Display';
  font-weight: map-get($font-weights, regular);
}